export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBC2xRy1A5jPze3wjlyVk8X13qwpd4pcCg",
    authDomain: "orlicollect.firebaseapp.com",
    databaseURL: "https://orlicollect-default-rtdb.firebaseio.com",
    projectId: "orlicollect",
    storageBucket: "orlicollect.appspot.com",
    messagingSenderId: "332074322082",
    appId: "1:332074322082:web:79f1a91928f2b89521effb",
    measurementId: "G-GTCKTB39HX"
  },
  appName: "OrliCabDriver",
  langArr: [
    { name: 'English', code: 'en' },
    { name: 'española', code: 'es' },
    { name: 'عربى', code: 'ar' },
    { name: '中文', code: 'cn' }
  ],
  mapOptions: {
    zoom: 17,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false
  }
};



export let POSITION_INTERVAL = 10 * 1000; // 10s for refreshing geolocation

export let DEAL_STATUS_PENDING = 'pending';
export let DEAL_STATUS_ACCEPTED = 'accepted';
export let DEAL_TIMEOUT = 20; // 20 seconds

export let TRIP_STATUS_WAITING = 'waiting';
export let TRIP_STATUS_GOING = 'going';
export let TRIP_STATUS_FINISHED = 'finished';
export let TRIP_STATUS_CANCELED = 'canceled';
export let TRANSACTION_TYPE_WITHDRAW = 'withdraw';

export let PLAY_AUDIO_ON_REQUEST = true;
export let AUDIO_PATH = "./assets/audio/sound.mp3" //must have mp3 file

export let DEFAULT_AVATAR = './assets/img/default.png';
